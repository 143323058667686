import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel CSS
import './Carousal.css';
import img1 from './../../assets/images/Corousal1.jpeg'; // Replace with the correct paths to your images
import upperman from './../../assets/images/upperman.png';
import img3 from './../../assets/images/Corousal3.jpeg';
import img4 from './../../assets/images/Corousal4.jpeg';



const Container = () =>{
  const [index,setIndex] = useState(0)
  let textJson = [
    {heading1:"Unlock the Secrets of Loans, Rates and Repayment Options.",heading2:"Let's get started with yours"},
  { heading1: "Comprehensive Knowledge on Loan Types and Eligibility Criteria.", heading2: "Get the funds you need now" },
  { heading1: "Easy Tips on Managing Loan Repayments.", heading2: "Discover your options today" },
  { heading1: "Easy Loan Tips Learn How to Choose the Right Loan for You.", heading2: "Start your journey with us today" },
  { heading1: "Dive Deeper into Loans Clear Info on Interest, Better Control.", heading2: "Find the perfect loan for you" }]



  let hindiText = [
    
      { "heading1": "ऋण, दरें और पुनर्भुगतान विकल्पों के राज़ जानें।", "heading2": "आइए आपका ऋण शुरू करें" },
      { "heading1": "ऋण के प्रकार और पात्रता मानदंड की पूरी जानकारी।", "heading2": "अभी फंड प्राप्त करें" },
      { "heading1": "ऋण चुकाने के आसान सुझाव।", "heading2": "आज ही अपने विकल्प जानें" },
      { "heading1": "सही ऋण कैसे चुनें, जानने के आसान तरीके।", "heading2": "आज ही हमारे साथ शुरुआत करें" },
      { "heading1": "ऋण पर गहरी जानकारी: ब्याज और बेहतर नियंत्रण।", "heading2": "अपने लिए सही ऋण पाएं" }
    
    
    ]
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % textJson.length);
    }, 4000);
    
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [textJson.length]);

  return(
    <div class="main-container">
    <div className='heading-container'>
    <h1 className='h1Heading'>{localStorage.getItem("language")==="Hindi" ? hindiText[index].heading1   :textJson[index].heading1}</h1>
    <h3 className='h3Heading'>{localStorage.getItem("language")==="Hindi"  ? hindiText[index].heading2:textJson[index].heading2}</h3>
    {/* <button class="apply-now"> */}
    {/* <span class="apply-text">APPLY NOW</span> */}
    {/* <span class="arrow"></span> */}
  {/* </button> */}
    </div>
    <img src={upperman} alt="Overlay Image" class="overlay-image" />
  </div>
  )
}

export default Container;
