// src/ProductHighlight.js
import React from 'react';
import './ProductHighlight.css';
import productImage from './../../assets/images/working.png'; // Replace with the actual path to your image

const ProductHighlight = () => {
  return (
    <section className="product-highlight-section">
      <div className="product-image">
        <img src={productImage} alt="Product Highlight" className='product-image' />
      </div>
      <div className="product-text">
        <h1>{localStorage.getItem("language")==="Hindi"?"डीएन फिन्स क्यों":"Why DNFinS"}</h1>
        {localStorage.getItem("language")==="Hindi"  ?<p>
          डीएनफिनएस को चुनना मतलब आपके विशेष जरूरतों के लिए व्यक्तिगत वित्तीय समाधान और विशेषज्ञ मार्गदर्शन का चयन करना है। हमारे अनुभवी वित्तीय सलाहकार हर कदम पर आपका समर्थन करने के लिए समर्पित हैं, जिससे आप अपनी वित्तीय यात्रा के दौरान आत्मविश्वास और सूचित महसूस करें। डीएनफिनएस में, हम पूरी पारदर्शिता में विश्वास करते हैं, इसलिए आपको कभी भी छिपी हुई फीस या अस्पष्ट शर्तों का सामना नहीं करना पड़ेगा। ईमानदारी और सत्यनिष्ठा के प्रति हमारी प्रतिबद्धता एक सहज और सरल अनुभव की गारंटी देती है, जिससे आपकी वित्तीय निर्णय लेने की प्रक्रिया सुचारू और स्पष्ट हो जाती है। हम व्यापक वित्तीय सेवाओं की एक विस्तृत श्रृंखला प्रदान करते हैं, जिनमें होम लोन, संपत्ति के बदले लोन, एमएसएमई लोन, कार्यशील पूंजी, और विशेष संपत्ति लोन शामिल हैं, जो विभिन्न आवश्यकताओं को पूरा करते हैं।

        </p>:<p>
        Choosing DNFinS means opting for personalized financial solutions with expert guidance tailored to your unique needs. Our experienced financial advisors are dedicated to providing you with support every step of the way, ensuring you feel confident and informed throughout your financial journey. At DNFinS, we believe in complete transparency, so you'll never encounter hidden fees or unclear terms. Our commitment to honesty and integrity guarantees a hassle-free experience, making your financial decision-making process smooth and straightforward. We offer a wide range of comprehensive financial services, including home loans, loans against property, MSME loans, working capital, and specialized property loans, to meet diverse needs.
        </p>}
        {/* <button className="learn-more-button">Learn More</button> */}
      </div>
    </section>
  );
};

export default ProductHighlight;
