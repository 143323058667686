import React, { useState } from 'react';
import './Calculator.css';

const EMICalculator = () => {
  const [principal, setPrincipal] = useState('');
  const [rate, setRate] = useState('');
  const [tenure, setTenure] = useState('');
  const [tenureType, setTenureType] = useState('months');
  const [emiDetails, setEmiDetails] = useState(null);
  const [emis, setEmis] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [emisPerPage] = useState(12);

  const calculateEMI = (P, R, N) => {
    R = R / (12 * 100); // monthly interest rate
    const emi = (P * R * Math.pow(1 + R, N)) / (Math.pow(1 + R, N) - 1);
    return emi;
  };

  const preventNegativeInput = (e) => {
    if (e.key === '-' || e.key === 'e' || e.key === 'E' || e.key === '+') {
      e.preventDefault();
    }
  };

  const handleCalculate = () => {
    const P = parseFloat(principal);
    const annualRate = parseFloat(rate);
    let N = parseFloat(tenure);

    if (tenureType === 'years') {
      if (N > 30) {
        alert('Tenure should not exceed 30 years');
        return;
      }
    } else {
      if (N > 360) {
        alert('Tenure should not exceed 360 months');
        return;
      }
    }

    if (tenureType === 'years') {
      N *= 12; // Convert years to months for EMI calculation
    }

    if (isNaN(P) || isNaN(annualRate) || isNaN(N)) {
      alert('Please enter valid numbers');
      return;
    }

    const monthlyEMI = calculateEMI(P, annualRate, N);
    let remainingPrincipal = P;
    const emisList = [];

    for (let i = 1; i <= N; i++) {
      const interestPaid = remainingPrincipal * (annualRate / (12 * 100));
      const principalPaid = monthlyEMI - interestPaid;
      remainingPrincipal -= principalPaid;

      emisList.push({
        period: i,
        emi: monthlyEMI.toFixed(2),
        interestPaid: interestPaid.toFixed(2),
        principalPaid: principalPaid.toFixed(2),
        remainingPrincipal: remainingPrincipal.toFixed(2)
      });
    }

    const totalAmount = monthlyEMI * N;
    const totalInterest = totalAmount - P;

    setEmis(emisList);
    setEmiDetails({
      emi: monthlyEMI.toFixed(2),
      totalAmount: totalAmount.toFixed(2),
      totalInterest: totalInterest.toFixed(2),
    });
    setCurrentPage(1);
  };

  // Pagination logic
  const indexOfLastEmi = currentPage * emisPerPage;
  const indexOfFirstEmi = indexOfLastEmi - emisPerPage;
  const currentEmis = emis.slice(indexOfFirstEmi, indexOfLastEmi);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="calculator-container">
      {localStorage.getItem("language") === "Hindi" ? <h1 className='emih1'>ईएमआई कैलकुलेटर</h1> : <h1 className='emih1'>EMI Calculator</h1>}
      <form>
        <label>
          {localStorage.getItem("language") === "Hindi" ? "मूल धन" : "Principal Amount"}:
          <input
          onKeyDown={preventNegativeInput}
            type="number"
            min="0"
            step="1" // Ensures only whole positive numbers can be entered
            value={principal}
            onChange={(e) => setPrincipal(e.target.value)}
          />
        </label>
        <label>
          {localStorage.getItem("language") === "Hindi" ? "ब्याज की दर" : "Rate of Interest"} (%):
          <input
          onKeyDown={preventNegativeInput}
            type="number"
            min="0"
            step="1" // Ensures only whole positive numbers can be entered
         
            value={rate}
            onChange={(e) => setRate(e.target.value)}
          />
        </label>
        <label>
          {localStorage.getItem("language") === "Hindi" ? "कार्यकाल" : "Tenure"}:
          <input
          onKeyDown={preventNegativeInput}
            type="number"
            min="0"
            step="1" // Ensures only whole positive numbers can be entered
         
            value={tenure}
            onChange={(e) => setTenure(e.target.value)}
          />
        </label>
        <div className="tenure-type">
          <label>
            <input
              type="radio"
              checked={tenureType === 'months'}
              onChange={() => setTenureType('months')}
            />
            {localStorage.getItem("language") === "Hindi" ? "महीने" : "Months"}
          </label>
          <label>
            <input
              type="radio"
              checked={tenureType === 'years'}
              onChange={() => setTenureType('years')}
            />
            {localStorage.getItem("language") === "Hindi" ? "साल" : "Years"}
          </label>
        </div>
        <button type="button" onClick={handleCalculate}>
          {localStorage.getItem("language") === "Hindi" ? "ईएमआई की गणना करें" : "Calculate EMI"}
        </button>
      </form>

      {emiDetails && (
        <div className="emi-details">
          <h2>{localStorage.getItem("language") === "Hindi" ? "ईएमआई विवरण" : "EMI Details"}</h2>
          <p>{localStorage.getItem("language") === "Hindi" ? "ईएमआई" : "EMI"}: ₹ {emiDetails.emi} {localStorage.getItem("language") === "Hindi" ? "साल" : "per"} {tenureType === 'years' ? 'month' : 'month'}</p>
          <p>{localStorage.getItem("language") === "Hindi" ? "कुल राशि" : "Total Amount"}: ₹ {emiDetails.totalAmount}</p>
          <p>{localStorage.getItem("language") === "Hindi" ? "कुल ब्याज" : "Total Interest"}: ₹ {emiDetails.totalInterest}</p>
        </div>
      )}

      {emis.length > 0 && (
        <div className="emis-list">
          <h2>
            {localStorage.getItem("language") === "Hindi" ?
              <span>{tenureType === 'months' ? 'महीने' : 'साल'}</span> :
              <span>{tenureType === 'months' ? 'Monthly' : 'Yearly'}</span>} {localStorage.getItem("language") === "Hindi" ? "ईएमआई ब्रेकडाउन" : "EMI Breakdown"}
          </h2>
          <table>
            <thead>
              <tr>
                <th>{localStorage.getItem("language") === "Hindi" ? 'महीने' : 'Month'}</th>
                <th>{localStorage.getItem("language") === "Hindi" ? "ईएमआई" : "EMI"}</th>
                <th>{localStorage.getItem("language") === "Hindi" ? "ब्याज भुगतान" : "Interest Paid"}</th>
                <th>{localStorage.getItem("language") === "Hindi" ? "मूलधन भुगतान" : "Principal Paid"}</th>
                <th>{localStorage.getItem("language") === "Hindi" ? "शेष प्राचार्य" : "Outstanding Balance"}</th>
              </tr>
            </thead>
            <tbody>
              {currentEmis.map((emi, index) => (
                <tr key={index}>
                  <td>{emi.period}</td>
                  <td>₹ {emi.emi}</td>
                  <td>₹ {emi.interestPaid}</td>
                  <td>₹ {emi.principalPaid}</td>
                  <td>₹ {emi.remainingPrincipal}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: Math.ceil(emis.length / emisPerPage) }, (_, index) => (
              <button key={index} onClick={() => paginate(index + 1)}>
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EMICalculator;
