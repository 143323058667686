import React from 'react';
import './Footer.css';
import { FaFacebook, FaLinkedin, FaTwitter, FaInstagram, FaGoogle } from 'react-icons/fa';
import productImage from './../../assets/images/TechWebsite.png'; // Replace with the actual path to your image
import backgroundImage from './../../assets/images/footer.jpg'; // Replace with the actual path to your background image

const Footer = () => {
  return (
    <footer className="footer-container">
       <div className="google-map">
      <h2 className="footer-title">
      {localStorage.getItem("language")==="Hindi" ?"संपर्क करें":"Contact Us"}</h2>
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112023.74223957254!2d77.34716949431619!3d28.667444458863738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf13be3fb777b%3A0xac643233dfb86295!2zRE5GaW5TIHwgTE9BTiDgpJXgpYAg4KSq4KS-4KSg4KS24KS-4KSy4KS-!5e0!3m2!1sen!2sin!4v1722020995525!5m2!1sen!2sin"
          width="120%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112023.74223957254!2d77.34716949431619!3d28.667444458863738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf13be3fb777b%3A0xac643233dfb86295!2zRE5GaW5TIHwgTE9BTiDgpJXgpYAg4KSq4KS-4KSg4KS24KS-4KSy4KS-!5e0!3m2!1sen!2sin!4v1722020995525!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      </div>
      <div className="contact-info">
      <img src={productImage} alt="Image 1" className="top-image" />
      <p className="contact-details bold">{localStorage.getItem("language")==="Hindi" ?"फ़ोन":"Phone"}</p>
        <p className="contact-details">     +91-9289610125  
        </p>
        <p className="contact-details">      1800 889 6330
        </p>
       
        <p className="contact-details bold">{localStorage.getItem("language")==="Hindi" ?"ईमेल":"Email"}</p>
        <p className="contact-details">info@dnfins.com</p>
        <div className="social-icons">
        <a href="https://www.facebook.com/share/SKRje6VGyUun1ur2/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer" className="icon"><FaFacebook /></a>
        <a href="https://g.co/kgs/FWVA9Ez" target="_blank" rel="noopener noreferrer" className="icon"><FaGoogle /></a>
        <a href="https://www.linkedin.com/company/dnfins/" target="_blank" rel="noopener noreferrer" className="icon"><FaInstagram /></a>
        <a href="https://www.instagram.com/dnfinancial.services?igsh=OTh0NXhhcWUyMmMw" target="_blank" rel="noopener noreferrer" className="icon"><FaLinkedin /></a>
      </div>
      </div>
    </footer>
  );
};

export default Footer;
